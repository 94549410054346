import request from './request'

export function list(params) {
    return request.get('/api/admin/code/all', { params })
}

export function get(id) {
    return request.get('/api/admin/code/', { params: { id } })
}

export function create(body) {
    return request.post('/api/admin/code/', body)
}

export function update(body) {
    return request.put('/api/admin/code/', body)
}

export function del(id) {
    return request.delete('/api/admin/code/', { data: { id } })
}

export function unbind(body) {
    return request.put('/api/admin/code/unbind', body)
}

export function ban(body) {
    return request.put('/api/admin/code/ban', body)
}

export function createCode(body) {
    return request.post('/api/admin/code', body)
}