import React from 'react'
import { Table, Form, Input, Button, Modal, Select, InputNumber, Popconfirm, Col, Row } from 'antd'
import './index.less';
import moment from 'moment'
import JSZip from 'jszip'

import * as codeApi from '../../api/code'

const initParams = {
    page: 0,
    paseSize: 100
}
class Index extends React.Component {
    formRef = React.createRef()
    downloadList = []
    state = {
        columns: [
            { title: '序号', render: (text, record, index) => (<span>{(index + 1)}</span>), width: 100, fixed: 'left', },
            {
                title: '挪车码id', dataIndex: 'code_id', width: 120, render: (text, record) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                        {text}
                    </div>
                ),
            },
            {
                title: '用户id', dataIndex: 'authing_user_id', width: 100, render: (text, record) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                        {text}
                    </div>
                ),
            },
            { title: '车牌', dataIndex: 'license', width: 120, },
            { title: '手机号码', dataIndex: 'phone', width: 120 },
            { title: '生成渠道', dataIndex: 'channel', width: 120, render: (text) => (<span>{{ 'weapp': '微信', 'admin': '管理后台' }[text]}</span>) },
            { title: '挪车码状态', dataIndex: 'status', width: 140, render: (text) => (<span>{{ '1': '未绑定', '2': '已绑定', '3': '已解绑', '4': '已禁用' }[text]}</span>) },
            { title: '挪车码类型', dataIndex: 'code_type', width: 120, render: (text) => (<span>{{ '0': 'DIY', '1': '开车包子狗款', '2': '开车粉熊款', '3': '滑板宇航员款', '4': '大哥别砸款', '5': '裸码' }[text]}</span>) },
            { title: '创建时间', dataIndex: 'creation_time', width: 120, render: (text) => (<span>{moment(text).format('YYYY/MM/DD HH:mm:ss')}</span>) },
            { title: '更新时间', dataIndex: 'updatedAt', render: (text) => (<span>{text && moment(text).format('YYYY/MM/DD HH:mm:ss')}</span>) },
            { title: '激活时间', dataIndex: 'activation_time', render: (text) => (<span>{text && moment(text).format('YYYY/MM/DD HH:mm:ss')}</span>) },
            { title: '解绑时间', dataIndex: 'unbind_time', render: (text) => (<span>{text && moment(text).format('YYYY/MM/DD HH:mm:ss')}</span>) },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                width: 120,
                render: (text, record) => {
                    function pop(text, clickFun) {
                        return (
                            <Popconfirm
                                title={"确认" + text + '吗？'}
                                onConfirm={clickFun}
                                okText="确定"
                                cancelText="取消"
                            >
                                <a style={{ display: 'block' }} >{text}</a>
                            </Popconfirm>
                        )
                    }
                    return (
                        <div>
                            {pop('禁用', this.onBanCode.bind(this, record))}
                            <a style={{ display: 'block' }} onClick={this.onShowCode.bind(this, record)}>查看</a>
                            {pop('解绑', this.onUnbindCode.bind(this, record))}
                            <a style={{ display: 'block' }} onClick={this.onDownloadCode.bind(this, record)}>下载</a>
                        </div>
                    )
                }
            }
        ],
        rows: [],
        searchParams: initParams,
        editItem: {},
        isEdit: false,
        isModalVisible: false,
        isCodeModalVisible: false,
        QRCodeValue: '',
        createLoading: false,
        selectedRowKeys: [],
        selectedRows: []
    }

    async componentDidMount() {
        await this.fetchData()
    }

    async fetchData() {
        const { searchParams } = this.state
        Object.keys(searchParams).forEach(key => {
            if (!searchParams[key]) delete searchParams[key]
        })
        const listResp = await codeApi.list(searchParams)
        if (listResp.errCode === 0) {
            const rows = listResp.data.rows
            this.setState({ rows })
        }
    }

    onShowCode(record) {
        window.open(record.source_dist)
    }

    async onBanCode(record) {
        await codeApi.ban({ code_id: record.code_id })
    }

    async onUnbindCode(record) {
        try {
            await codeApi.unbind({ code_id: record.code_id })
            await this.fetchData()
        } catch (error) {

        }
    }

    async onDownloadCode(record) {
        this.toBlob(record.source_dist, (blob) => {
            const url = URL.createObjectURL(blob);
            this.downloadFile(url, 'image.png')
        })

    }
    downloadFile(url, name) {
        // 创建 a 标签并下载文件
        const link = document.createElement('a');
        link.href = url
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();

        // 释放 Blob 对象的 URL 引用
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
    }
    toBlob(imgUrl, cb) {
        return new Promise((resolve) => {
            // 创建 img 标签并监听 load 事件
            const img = new Image();
            img.src = imgUrl.replace('https://cdn.digitalbp.cn/', 'https://carman-1255936829.cos.ap-nanjing.myqcloud.com/');
            img.crossOrigin = 'anonymous';

            img.onload = () => {
                // 创建 canvas 并绘制图片
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                // 从 canvas 获取图片数据并转换为 Blob 对象
                canvas.toBlob((blob) => {
                    cb && cb(blob)
                    resolve(blob)
                }, 'image/jpeg');
            };
        })
    }

    // onHideCode() {
    //     this.setState({ isCodeModalVisible: false, QRCodeValue: '' })
    // }

    onAdd() {
        // 展示对话框
        this.setState({ isModalVisible: true, isEdit: false })
        // if (this.formRef.current) this.formRef.current.resetFields()
    }

    onEdit(record) {
        this.setState({ isModalVisible: true, isEdit: true, editItem: record })
        if (this.formRef.current) this.formRef.current.setFieldsValue(record)
    }

    onDelete(record) {
        Modal.error({
            closable: true,
            okText: '确认',
            title: `即将删除：${record.subject}`,
            onOk: () => {
                codeApi.del(record.id)
                    .finally(() => {
                        this.fetchData()
                    })
            }
        })
    }

    async onSeach(val) {
        this.setState({ searchParams: { ...this.state.searchParams, ...val } })
        await this.fetchData()
    }

    async handleCancel() {
        // this.setState({ isModalVisible: false })
        // await this.fetchData()
    }

    async onSubmit(form) {
        this.setState({
            createLoading: true
        })
        await this.createCode(form)
        this.setState({
            createLoading: false
        })
    }

    async createCode(form) {
        if (form.num === 0) {
            await this.downloadAll()
            return
        }
        const item = await codeApi.createCode(form)
        if (item.errCode === 0) {
            this.downloadList.push(item.data)
        }
        return this.createCode({
            type: form.type,
            num: --form.num
        })
    }

    onDownloadCodeList() {
        this.downloadList = this.state.selectedRows;
        this.downloadAll()
    }

    async downloadAll() {
        if (this.downloadList.length === 0) return
        const zip = new JSZip();
        await Promise.all(this.downloadList.map((item,) => {
            return zip.file(item.code_id + ".jpg", this.toBlob(item.source_dist));
        }))
        zip.generateAsync({ type: "blob" }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            this.downloadFile(url, "image.zip");
            this.downloadList = []
        });
    }

    onReset() {
        this.setState({ searchParams: initParams }, () => {
            this.fetchData()
        })
    }

    render() {
        const { rows, columns, isModalVisible, isCodeModalVisible, QRCodeValue } = this.state

        return (
            <div className='gift-container'>
                <div className='search-form-container'>
                    <Form layout="horizontal" onFinish={this.onSeach.bind(this)} onReset={this.onReset.bind(this)}>
                        <Row gutter={24}>
                            <Col span={8} >
                                <Form.Item label="挪车码id" name='code_id'>
                                    <Input placeholder="输入挪车码id" />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item label="手机号码" name='phone'>
                                    <Input placeholder="输入手机号码" />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item label="车牌" name='license'>
                                    <Input placeholder="输入车牌" />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item label="挪车码状态" name="status" >
                                    <Select
                                        options={[
                                            { value: '1', label: '未绑定' },
                                            { value: '2', label: '已绑定' },
                                            { value: '3', label: '已解绑' },
                                            { value: '4', label: '已禁用' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item label="生成渠道" name="channel" >
                                    <Select
                                        options={[
                                            { value: 'weapp', label: '微信' },
                                            { value: 'admin', label: '管理后台' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item label="生成类型" name="code_type" >
                                    <Select
                                        options={[
                                            { value: '1', label: '开车包子狗款' },
                                            { value: '2', label: '开车粉熊款' },
                                            { value: '3', label: '滑板宇航员款' },
                                            { value: '4', label: '大哥别砸款' },
                                            { value: '5', label: '裸码' }
                                        ]}  
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col
                                span={24}
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <Button style={{margin: '0 8px',}} htmlType="submit">搜索</Button>
                                <Button style={{margin: '0 8px',}}  htmlType="reset">清空</Button>
                                <Button style={{margin: '0 8px',}}  onClick={this.onAdd.bind(this)}>批量添加</Button>
                                {
                                    this.state.selectedRowKeys.length > 0 &&
                                    <Button style={{margin: '0 8px',}}  onClick={this.onDownloadCodeList.bind(this)}>批量下载</Button>
                                }</Col>

                        </Row>

                    </Form>
                </div>

                <Table
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: this.state.selectedRowKeys,
                        onChange: (selectedRowKeys, selectedRows) => {
                            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                            this.setState({
                                selectedRowKeys: selectedRowKeys,
                                selectedRows: selectedRows
                            })
                        },
                        getCheckboxProps: (record) => ({
                            // Column configuration not to be checked
                            name: record.code_id,
                        }),

                    }}
                    rowKey='code_id'
                    columns={columns}
                    dataSource={rows}
                    scroll={{ x: true, y: true }}
                />

                {/* <Modal title="礼品兑换二维码" cancelText="关闭" okText="下载" visible={isCodeModalVisible} onCancel={this.onHideCode.bind(this)} onOk={this.onDownloadCode.bind(this)}>
                    <QRCode id="qrcode" value={QRCodeValue} size={290} level={"H"} includeMargin={true} />
                </Modal>*/}

                <Modal forceRender={true} footer={null} title="批量生成" visible={isModalVisible} onCancel={this.handleCancel.bind(this)}>
                    <Form labelCol={{ span: 6 }} initialValues={{ type: '1', num: 1 }} ref={this.formRef} onFinish={this.onSubmit.bind(this)} layout="horizontal">
                        <Form.Item label="选择款式" name="type" key='options'>
                            <Select
                                options={[
                                    { value: '1', label: '开车包子狗款' },
                                    { value: '2', label: '开车粉熊款' },
                                    { value: '3', label: '滑板宇航员款' },
                                    { value: '4', label: '大哥别砸款' },
                                    { value: '5', label: '裸码' }
                                ]}
                            />
                        </Form.Item>
                        <Form.Item label="生成数量" name="num" >
                            <InputNumber min={1} max={1000000} />
                        </Form.Item>
                        <Form.Item key='submit' wrapperCol={{ span: 14, offset: 6 }}>
                            <Button htmlType="submit" loading={this.state.createLoading}>
                                {this.state.createLoading ? '生成中，请勿关闭' : '确认'}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

            </div>
        )
    }
}

export default Index;
