import React from 'react'
import { Table, Form, Input, Button, Modal, Space } from 'antd'
import './index.less';
import moment from 'moment'
import QRCode from "qrcode.react"

import * as licenceApi from '../../api/licence'

class Index extends React.Component {
    formRef = React.createRef()

    state = {
        columns: [
            {
                title:'序号',
                render:(text,record,index)=> (<span>{(index+1)}</span>),
            },
            { title: '车牌号', dataIndex: 'licence', width: 120 },
            { title: '车主电话', dataIndex: 'phone', width: 120 },
            { title: '挪车码ID', dataIndex: 'codeId', width: 120 },
            {
                title: '是否隐私保护',
                width: 120,
                render: (text, record, index) => (<span>{record.isProtectPhone ? '是' : '否'}</span>)
            },
            {
                title: '是否微信通知',
                width: 120,
                render: (text, record, index) => (<span>{record.isWechatNotice ? '是' : '否'}</span>)
            },
            {
                title: '是否短信通知',
                width: 120,
                render: (text, record, index) => (<span>{record.isSmsNotice ? '是' : '否'}</span>)
            },
            {
                title: '创建时间',
                render: (text, record, index) => (<span>{moment(record.create_time).format('YYYY-MM-DD HH:mm:ss')}</span>)
            },
            {
                title: '最后更新时间',
                render: (text, record, index) => (<span>{moment(record.update_time).format('YYYY-MM-DD HH:mm:ss')}</span>)
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                  <Space size="middle">
                    <Button onClick={this.onShowCode.bind(this, record)}>查看挪车码</Button>
                    {/* <Button onClick={this.onEdit.bind(this, record)}>编辑</Button> */}
                    {/* <Button onClick={this.onDelete.bind(this, record)}>删除</Button> */}
                  </Space>
                )
            }
        ],
        rows: [],
        searchParams: {
            page: 0,
            paseSize: 100
        },
        editItem: {},
        isEdit: false,
        isCodeModalVisible: false,
        QRCodeValue: ''
    }

    async componentDidMount() {
        await this.fetchData()
    }

    async fetchData() {
        const { searchParams } = this.state
        Object.keys(searchParams).forEach(key => {
            if (!searchParams[key]) delete searchParams[key]
        })
        const listResp = await licenceApi.list(searchParams)
        if (listResp.msg === 'ok') {
            const rows = listResp.data.rows
            this.setState({ rows })
        }
    }

    onShowCode(record) {
        console.log('record: ', record)
    }

    onHideCode() {
        this.setState({ isCodeModalVisible: false, QRCodeValue: ''})
    }

    onDownloadCode() {
        // Generate download with use canvas and stream
        const canvas = document.getElementById("qrcode");
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = 'qrcode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    onAdd() {
        // 展示对话框
        this.setState({ isEdit: false })
        if (this.formRef.current) this.formRef.current.resetFields()
    }

    onEdit(record) {
        this.setState({ isEdit: true, editItem: record })
        if (this.formRef.current) this.formRef.current.setFieldsValue(record)
    }

    onDelete(record) {
        Modal.error({
            closable: true,
            okText: '确认',
            title: `即将删除：${record.name}`,
            onOk: () => {
                licenceApi.del(record.id)
                .finally(() => {
                    this.fetchData()
                })
            }
        })
    }

    async onSeach(val) {
        this.setState({ searchParams: val })
        await this.fetchData()
    }

    async onSubmit(form) {
        const { isEdit, editItem } = this.state
        if (!form.count) form.count = 0
        form.draw = form.draw ? 1 : 0

        if (isEdit) {
            await licenceApi.update({ ...form, id: editItem.id })
        } else {
            await licenceApi.create(form)
        }
        await this.handleCancel()
    }
  

    render () {
        const { rows, columns, isCodeModalVisible, QRCodeValue } = this.state

        return (
            <div className='gift-container'>
                <div className='search-form-container'>
                    <Form layout='inline' onFinish={this.onSeach.bind(this)}>
                        <Form.Item label="车牌号" name='licence'>
                            <Input placeholder="输入车牌号" />
                        </Form.Item>

                        <Form.Item key='submit'>
                            <Button htmlType="submit">立即搜索</Button>
                        </Form.Item>
                        <Form.Item>
                        <Button onClick={this.onAdd.bind(this)}>添加</Button>
                        </Form.Item>
                    </Form>
                </div>

                <Table columns={columns} dataSource={rows} />

                <Modal title="礼品兑换二维码" cancelText="关闭" okText="下载"  open={isCodeModalVisible} onCancel={this.onHideCode.bind(this)} onOk={this.onDownloadCode.bind(this)}>
                    <QRCode id="qrcode" value={QRCodeValue} size={290} level={"H"} includeMargin={true}/>
                </Modal>
            </div>
        )
    }
}

export default Index;
