import React from 'react';

import Layout from './pages/Layout'
import Login from './pages/Login'

import { me as meApi } from './api/user'

class App extends React.Component {
    state = {
        isLogin: false,
        userInfo: {}
    }

    componentDidMount() {
        meApi()
            .then((response) => {
                if (response) {
                    this.setState({ userInfo: response, isLogin: true })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const { isLogin } = this.state

        return (
            <div>
                { isLogin  && <Layout /> }
                { !isLogin  && <Login /> }
            </div>
        )
    }
}

export default App;
