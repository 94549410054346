import React from 'react'
import { DatePicker, Table } from 'antd'
import './index.less'

import moment from 'moment'

class Index extends React.Component {
    state = {
        columns: [
            {
                title:'序号',
                render:(text,record,index)=>{
                    //生成序号
                  return(
                    <span>{(index+1)}</span>
                  )
                },
                width: 20
            },
            { title: '名称', dataIndex: 'name', width: 120 },
            { title: '数量', dataIndex: 'count', width: 120 }
        ],
        data: []
    }

    async componentDidMount() {
        await this.fetchData(moment().format('YYYY-MM-DD'))
    }

    async fetchData(date) {
        const dataList = ''// await userCheckpointApi.report(date)
        this.setState({ data: dataList || [] })
    }

    async onDateChange(date) {
        this.setState({
            date: date.format('YYYY-MM-DD')
        })
        await this.fetchData(date.format('YYYY-MM-DD'))
    }

    render () {
        const { data, columns } = this.state

        const disabledDate = (curent) => {
            return curent && moment().diff(curent) < 0
        }

        return (
            <div className='dashboard-container'>
                <div className='dashboard-form-container'>
                    <DatePicker placeholder="选择日期" onChange={this.onDateChange.bind(this)} disabledDate={disabledDate}/>
                </div>

                <Table columns={columns} dataSource={data} />
            </div>
        )
    }
}

export default Index;
