import React from 'react'
import { Table, Form, Input, Button, Modal } from 'antd'
import './index.less';
import moment from 'moment'

import * as userApi from '../../api/user'

class Index extends React.Component {
    state = {
        columns: [
            { title:'序号', render:(text,record,index) => (<span>{(index+1)}</span>) },
            { title: '用户ID', dataIndex: 'id' },
            { title: '姓名', dataIndex: 'username' },
            { title: '电话', dataIndex: 'phone' },
            {
                title: '创建时间',
                render: (text,record,index) => (<span>{moment(record.create_time).format('YYYY-MM-DD HH:mm:ss')}</span>),
            },
            {
                title: '最后更新时间',
                render: (text,record,index) => (<span>{moment(record.update_time).format('YYYY-MM-DD HH:mm:ss')}</span>)
            },
        ],
        rows: [],
        searchParams: {
            page: 0,
            paseSize: 100
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    async fetchData() {
        const { searchParams } = this.state
        Object.keys(searchParams).forEach(key => {
            if (!searchParams[key]) delete searchParams[key]
        })
        const listResp = await userApi.list(searchParams)
        if (listResp.msg === 'ok') {
            const rows = listResp.data.rows
            this.setState({ rows })
        }
    }

    onDelete(record) {
        Modal.error({
            closable: true,
            okText: '确认',
            title: `即将删除用户：${record.nickname}`,
            onOk: () => {
                userApi.del(record.id)
                .finally(() => {
                    this.fetchData()
                })
            }
        })
    }

    render () {
        const { rows, columns } = this.state

        const onFinish = async (val) => {
            this.setState({ searchParams: val })
            await this.fetchData()
        }

        return (
            <div className='user-container'>
                <div className='search-form-container'>
                    <Form layout='inline' onFinish={onFinish}>
                        <Form.Item label="用户名" name='username'>
                            <Input placeholder="输入用户名" />
                        </Form.Item>
                        <Form.Item label="电话号码" name='phone'>
                            <Input placeholder="输入电话号码" />
                        </Form.Item>

                        <Form.Item key='submit'>
                            <Button htmlType="submit">搜索</Button>
                        </Form.Item>

                        {/* <Form.Item>
                            <Button onClick={onDownload}>下载</Button>
                        </Form.Item> */}
                    </Form>
                </div>

                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={rows}
                />
            </div>
        )
    }
}

export default Index;
