import request from './request'

export function list(params) {
    return request.get('/api/admin/licence/all', { params })
}

export function get(id) {
    return request.get('/api/admin/licence/', { params: { id } })
}

export function create(body) {
    return request.post('/api/admin/licence/', body)
}

export function update(body) {
    return request.put('/api/admin/licence/', body)
}

export function del(id) {
    return request.delete('/api/admin/licence/', { data: { id } })
}
