import request from './request'

export function list(params) {
    return request.get('/api/admin/user/all', { params })
}

export function get(id) {
    return request.get('/api/admin/user', { params: { id } })
}

export function me() {
    return request.get('/api/admin/user/me')
}

export function create(body) {
    return request.post('/api/admin/user', { body })
}

export function update(body) {
    return request.put('/api/admin/user', body)
}

export function del(id) {
    return request.delete('/api/admin/user', { data: { id } })
}
