import React from 'react';
import { HashRouter as Router, Route, NavLink } from 'react-router-dom';
import { Layout, Menu, Breadcrumb } from 'antd';
import {
    PieChartOutlined,
    UserOutlined,
    QrcodeOutlined,
    InboxOutlined,
} from '@ant-design/icons';

import './Layout.less';
import Dashboard from './Dashboard'
import User from './User'
import Licence from './Licence'
import Code from './Code'

const { Header, Content, Footer, Sider } = Layout;

class App extends React.Component {
    state = {
        collapsed: false,
    };

    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    render() {
        const { collapsed } = this.state;

        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Router>
                    <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
                        <div className="logo">
                            <NavLink to='/'>
                                轻挪助手
                            </NavLink>
                        </div>

                        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                            <Menu.Item key="1" icon={<PieChartOutlined />}>
                                <NavLink to='/dashboard'>
                                    dashboard
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="2" icon={<UserOutlined />}>
                                <NavLink to='/users'>
                                    用户管理
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="3" icon={<InboxOutlined />}>
                                <NavLink to='/licence'>
                                    车牌管理
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="4" icon={<QrcodeOutlined />}>
                                <NavLink to='/code'>
                                    小程序码管理
                                </NavLink>
                            </Menu.Item>
                        </Menu>
                    </Sider>

                    <Layout className="site-layout">
                        <Header className="site-layout-background" style={{ padding: 0 }} />
                        <Content style={{ margin: '0 16px' }}>
                            <Breadcrumb style={{ margin: '16px 0' }}>
                                {/* <Breadcrumb.Item></Breadcrumb.Item> */}
                            </Breadcrumb>

                            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                                <Route path="/dashboard" component={Dashboard} />
                                <Route path="/users" component={User} />
                                <Route path="/licence" component={Licence} />
                                <Route path="/code" component={Code} />
                            </div>
                        </Content>

                        <Footer style={{ textAlign: 'center' }}>HYCAN Admin ©2022 Created by digitalhuazhang</Footer>
                    </Layout>
                </Router>
            </Layout>
        );
    }
}

export default App;
