import axios from 'axios'

const instance = axios.create({
    timeout: 15000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})

// request拦截器
instance.interceptors.request.use(
    config => {
        return config
    },
    error => {
        Promise.reject(error)
    }
)

instance.interceptors.response.use(response => {
    return response.data
})


export default instance
