import React from 'react'
import { Form, Input, Button } from 'antd'

import './index.less'

import * as authApi from '../../api/auth'

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
}

class Index extends React.Component {
  onFinish(values) {
    authApi.adminLogin(values)
      .then(() => {
        window.location.reload()
      })
      .catch(error => {
        alert("账号密码错误")
      })
  }

  onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo)
  }

  render () {
    return (
      <div className='login-form-container'>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed.bind(this)}
        >
          <Form.Item
            label="用户名"
            name="username"
            rules={[
              {
                required: true,
                message: '请输入用户名',
              },
            ]}
          >
            <Input placeholder='请输入用户名'/>
          </Form.Item>

          <Form.Item
            label="密码"
            name="password"
            rules={[
              {
                required: true,
                message: '请输入密码'
              }
            ]}
          >
            <Input.Password placeholder='请输入密码'/>
          </Form.Item>

          <Form.Item style={{
            textAlign: 'right'
          }}>
            <Button type="primary" htmlType="submit">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default Index